import React, { useEffect } from "react";
import Lenis from '@studio-freight/lenis';
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import WhitePaper from "./pages/WhitePaper";
import Explorer from "./pages/Explorer";
import PitchDeck from "./pages/PitchDeck";
import Litepaper from "./pages/Litepaper";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Block from "./pages/Block";
import TransactionDetails from "./pages/TransactionDetails";
import Address from "./pages/Address";

function App() {

  const location = useLocation()

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (!location.hash)
        window.scrollTo(0, 0);
      else {
        const div = document.getElementById(location.hash.replace('#', ''))
        div?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1)
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/whitepaper" element={<WhitePaper />} /> */}
      <Route path="/explorer" element={<Explorer />} />
      <Route path="/pitch-deck" element={<PitchDeck />} />
      <Route path="/litepaper" element={<Litepaper />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/block/:block" element={<Block />} />
      <Route path="/tx/:txHash" element={<TransactionDetails />} />
      <Route path="/address/:address" element={<Address />} />
    </Routes>
  );
}

export default App;