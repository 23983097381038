import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import { Modal } from 'react-bootstrap';

function Header({ activeMenu, className }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <nav className={`navbar navbar-expand-lg${className ? ` ${className}` : ''}`}>
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src='/assets/image/logo.svg' alt='logo' />
                    </a>
                    <div className='d-lg-none d-flex align-items-center gap-md-3 gap-1'>
                        <img src='/assets/image/search_icon.svg' alt='search' className='pointer search_mobile_icon' onClick={handleShow} />

                        <button className="navbar-toggler" id='menu_icon' type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <img src='/assets/image/menu.svg' alt='menu' />
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-lg-auto mb-2 mb-lg-0 gap-4 align-items-lg-center">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/#documents" onClick={() => {
                                    document.getElementById('menu_icon')?.click();
                                    setTimeout(() => {
                                        const docs = document.getElementById('documents')
                                        window?.scrollTo(0, docs?.offsetTop - 150);
                                    }, 0);
                                }}>
                                    Docs
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link${activeMenu == 'EXPLORER' ? ' active' : ''}`} aria-current="page" to="/explorer" onClick={() => document.getElementById('menu_icon')?.click()}>Explorer</Link>
                            </li>
                            {/*<li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/#about" onClick={() => {
                                    document.getElementById('menu_icon')?.click();
                                    setTimeout(() => {
                                        const docs = document.getElementById('about')
                                        window?.scrollTo(0, docs?.offsetTop - 150);
                                    }, 0);
                                }}>
                                    About Us
                                </a>
                            </li>*/}
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/#contact" onClick={() => {
                                    document.getElementById('menu_icon')?.click();
                                    setTimeout(() => {
                                        const docs = document.getElementById('contact')
                                        window?.scrollTo(0, docs?.offsetTop - 150);
                                    }, 0);
                                }}>
                                    Contact Us
                                </a>
                            </li>
                            <li className="nav-item d-lg-block d-none">
                                <img src='/assets/image/search_icon.svg' alt='search' className='pointer' onClick={handleShow} />
                            </li>
                            <li className="nav-item">
                                <a className="nav-link download_app" aria-current="page" href="https://play.google.com/store/apps/details?id=com.cloud.earning" target='_blank'>Download App</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='searchbox_modal'>
                    <Modal show={show} onHide={handleClose} contentClassName='h-auto modal_content' fullscreen>
                        <Modal.Body className='modal_body'>
                            <div className='container'>
                                <div className='w-100 d-flex align-itmes-center justify-content-between'>
                                    <a className="navbar-brand d-md-flex d-none" href="/">
                                        <img src='/assets/image/logo.svg' alt='logo' />
                                    </a>

                                    <SearchBar className={"header_search_box"} setShow={setShow}/>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </nav>
        </>
    )
}

export default Header