import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios'
import cogoToast from 'cogo-toast';

function Home() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    })

    const [validation, setValidation] = useState({
        name: false,
        email: false,
        message: false
    })
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        if (loading) {
            return
        }
        try {
            const { name, email, message } = formData
            if (!name) {
                setValidation({ ...validation, name: true })
                return
            }
            if (!email || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/).test(email)) {
                setValidation({ ...validation, email: true })
                return
            }
            if (!message) {
                setValidation({ ...validation, message: true })
                return
            }
            setLoading(true)

            try {
                await axios.post('https://formsubmit.co/ajax/3f0269299ab86d85222651de588feb06',
                    {
                        _subject: 'Contact Form Inquiry Submission',
                        name: formData.name,
                        email: formData.email,
                        message: formData.message
                    })
                cogoToast.success('Form Details send successfully');
                setFormData({ name: '', email: '', message: '' })
            } catch (error) {
                console.log(error)
                cogoToast.error('Error while submitting details.');
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <>
            <div className='overflow-hidden round_blur'>
                <Header />

                <div className='round_img'>
                    <section className='section_style hero_section'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-lg-6 text-center'>
                                    <h1 className='hero_title'>Mobile Blockchain</h1>
                                    <p className='hero_p'>Download now and start earning, staking, creating <br className='d-lg-block d-none' /> NFTs with AI and more.</p>
                                    <div className='d-flex align-items-center justify-content-center gap-2'>
                                        <a href='https://apps.apple.com/us/app/fone-network/id1665915235' target='_blank' className='download_btn store_btn'>
                                            <img src='/assets/image/app_store.svg' alt='app store' />
                                        </a>
                                        <a href='https://play.google.com/store/apps/details?id=com.cloud.earning' target='_blank' className='download_btn store_btn'>
                                            <img src='/assets/image/google_playstore.svg' alt='google play store' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='section_style fone_network'>
                        <div className='container-fluid px-0'>
                            <div className='video_style'>
                                <video autoPlay loop muted playsInline className='w-100'>
                                    <source src="/assets/image/video.mp4" />
                                </video>
                            </div>
                        </div>
                    </section>

                    <section className='section_style'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-11'>
                                    <span className='title_span'>What is Fone Network?</span>
                                    <h2 className='title_h2'>Custom mobile blockchain & <br className='d-lg-block d-none' /> cryptocurrency in the <span className='font_white'>DePIN, NFT</span> & <span className='font_white'>Al ecosystems</span></h2>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='fone_card'>
                                        <div className='fonecard_detail pb-md-0'>
                                            <h3>Accessible</h3>
                                            <p>Available to all users anywhere at any time on all mobile devices.</p>
                                        </div>
                                        <img src='/assets/image/accessible_network.svg' alt='accessible network' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 mt-md-0 mt-4'>
                                    <div className='fone_card'>
                                        <img src='/assets/image/instant_transaction.svg' alt='instant transaction' className='w-100' />
                                        <div className='fonecard_detail pt-md-0'>
                                            <h3>Instant</h3>
                                            <p>Send & Receive Fone World-Wide in a matter of seconds.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 mt-lg-0 mt-4'>
                                    <div className='fone_card'>
                                        <img src='/assets/image/secure_transaction.svg' alt='secure transaction' className='w-100' />
                                        <div className='fonecard_detail pt-md-0'>
                                            <h3>Secure</h3>
                                            <p>Each device maintains an identical copy of the ledger, ensuring distributed consensus and resilience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section className='section_style decentralized_section'>
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-12'>
                                <div className='depin_img'>
                                    <div className='row h-100'>
                                        <div className='col-lg-6 d-lg-flex justify-content-lg-between flex-column h-100'>
                                            <div>
                                                <h2 className='decentralized_h2'>
                                                    Decentralized <br className='d-lg-block d-none' />
                                                    physical infrastructure <br className='d-lg-block d-none' />
                                                    network</h2>
                                                <p className='depin_p mb-lg-0 mb-3'>Open the app to sync it with the blockchain and <br className='d-lg-block d-none' /> <span className='font_yellow'>claim FONE rewards </span> daily.</p>
                                            </div>
                                            <a href='#' className='download_link justify-content-start me-auto ms-0'>
                                                <div className='download_btn'>
                                                    Start Earning
                                                </div>
                                            </a>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='depin_mobile'>
                                                <img src='/assets/image/depin_mobile.png' alt='depin mobile' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='section_style decentralized_section pt-0'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <span className='title_span'>Statistics</span>
                                <h2 className='title_h2 font_white mt-3 mb-5'>User Engagement</h2>
                            </div>
                        </div>
                        <div className='row app_traction_height justify-content-center'>
                            <div className='col-lg-4 col-md-6 app_col'>
                                <div className='app_traction_card dark_blue_card card_opacity'>
                                    <p className='app_traction_p'>Total download</p>
                                    <h3 className='app_traction_title'>5,800,000+</h3>
                                </div>
                                <div className='app_traction_card dark_blue_card app_col'>
                                    <p className='app_traction_p'>Growth (Since 2018)</p>
                                    <h3 className='app_traction_number'>90%</h3>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 app_col mt-md-0 mt-3'>
                                <div className='app_traction_card blue_card card_light_opacity'>
                                    <p className='app_traction_p font_blue_medium'>Monthly Active User</p>
                                    <h3 className='app_traction_title font_blue_dark'>120,000+</h3>
                                </div>
                                <div className='app_traction_card blue_card app_col'>
                                    <p className='app_traction_p font_blue_medium'>Growth (since 2022)</p>
                                    <h3 className='app_traction_number font_blue_dark'>3x</h3>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 app_col mt-lg-0 mt-3'>
                                <div className='app_traction_card yellow_card card_opacity'>
                                    <p className='app_traction_p font_grey'>NFTs Created</p>
                                    <h3 className='app_traction_title font_dark_grey'>90,000+</h3>
                                </div>
                                <div className='app_traction_card yellow_card app_col'>
                                    <p className='app_traction_p font_grey'>Total NFT Transactions</p>
                                    <h3 className='app_traction_number font_dark_grey'>1M*</h3>
                                </div>
                            </div>
                            {/* <div className='col-lg-3 app_col'>
                                <div className='app_traction_card grey_card card_opacity'>
                                    <p className='app_traction_p'>First Week Registration</p>
                                    <h3 className='app_traction_title'>5000*</h3>
                                </div>
                                <div className='app_traction_card grey_card app_col'>
                                    <p className='app_traction_p'>Total Registration of <br className='d-lg-block d-none' />
                                        Beta launch</p>
                                    <h3 className='app_traction_number'>15K+</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                <section className='section_style business_model_img'>
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-5'>
                                <span className='title_span'>FONE Model</span>
                                <h2 className='title_h2 font_white mt-3 mb-2'>Business Model</h2>
                            </div>
                            <div className='col-12 fone_margin text-end'>
                                <img src='/assets/image/fone_phone.png' alt='fone phone' />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='section_style blockchain_padding'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <span className='title_span'>Details</span>
                                <h2 className='title_h2 font_white mt-3 mb-0'>Blockchain Structure</h2>
                            </div>
                            <div className='col-12 mt-md-5 mt-4'>
                                <div className='blockchain_structure'>
                                    <div className='row w-100'>
                                        <div className='col-lg-6'></div>
                                        <div className='col-lg-6 d-flex justify-content-end px-lg-2 px-0'>
                                            <div className='blockchain_detail'>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>FONE Network Blockchain Type</p>
                                                    <p className='blockchain_detail_p font_white font_semi_bold'>Custom</p>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>Structure Type</p>
                                                    <p className='blockchain_detail_p font_white font_semi_bold'>Transactional</p>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>Ability to pass Metadata</p>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <p className='blockchain_detail_p font_white font_semi_bold'>Yes</p>
                                                        <img src='/assets/image/check.svg' alt='check' className='mb-2' />
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>NFT Support</p>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <p className='blockchain_detail_p font_white font_semi_bold'>Yes</p>
                                                        <img src='/assets/image/check.svg' alt='check' className='mb-2' />
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>Blockchain Validation Speed</p>
                                                    <p className='blockchain_detail_p font_white font_semi_bold'>12 Seconds</p>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>Network Fee</p>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <p className='blockchain_detail_p font_white font_semi_bold'>0.12 FONE</p>
                                                        <img src='/assets/image/fone_icon.svg' alt='fone icon' className='mb-2' />
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>Burn Support</p>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <p className='blockchain_detail_p font_white font_semi_bold'>Yes</p>
                                                        <img src='/assets/image/check.svg' alt='check' className='mb-2' />
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>Consensus Mechanism</p>
                                                    <p className='blockchain_detail_p font_white font_semi_bold'>Proof-of-stake</p>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between py-2 mt-lg-3 mt-2 blockchain_border'>
                                                    <p className='blockchain_detail_p'>Target Device Support</p>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <p className='blockchain_detail_p font_white font_semi_bold'>Mobile</p>
                                                        <img src='/assets/image/mobile_security.svg' alt='mobile security' className='mb-2' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='documents' className='section_style documents_section scroll_space'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6'>
                                <span className='title_span'>Important</span>
                                <h2 className='document_title mt-2'>Documents</h2>
                            </div>
                            <div className='col-lg-3 col-md-6 mt-md-0 mt-3'>
                                <a href='https://fone-network.gitbook.io/whitepaper' target='_blank'>
                                    <div className='document_detail'>
                                        <h3 className='document_detail_title'>Whitepaper</h3>
                                    </div>
                                </a>
                            </div>
                            <div className='col-lg-3 col-md-6 mt-lg-0 mt-3'>
                                <a href='/pitch-deck'>
                                    <div className='document_detail'>
                                        <h3 className='document_detail_title'>Pitch Deck</h3>
                                    </div>
                                </a>
                            </div>
                            <div className='col-lg-3 col-md-6 mt-lg-0 mt-3'>
                                <a href='/litepaper'>
                                    {/* https://fone.dev/litepaper */}
                                    <div className='document_detail'>
                                        <h3 className='document_detail_title'>Litepaper</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section id='about' className='section_style scroll_space'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <span className='title_span'>About Us</span>
                                <h2 className='title_h2 font_white mt-3 mb-0'>Team & Advisors</h2>
                            </div>
                            <div className='col-12 mt-md-5 mt-4'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6'>
                                        <div className='team_card'>
                                            <img src='/assets/image/team1.png' alt='team' className='w-100' />
                                            <div className='team_deail'>
                                                <div>
                                                    <h3 className='team_name'>Ivan Likov</h3>
                                                    <p className='roadmap_content m-0'>Founder, CEO & Lead Developer</p>
                                                </div>
                                                <a href='https://www.linkedin.com/in/ivan-likov-4b2a8329/' target='_blank'>
                                                    <img src='/assets/image/linkedin.svg' alt='linkedin' />
                                                </a>
                                            </div>
                                            <div>
                                                <p className='roadmap_content'>15 years of experience in mobile development.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>10 years in the blockchain and crypto space.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>6 years organic growing of the Fone Network blockchain and ecosystem.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>Niagara College graduate with multiple skills in various programming languages for mobile ane desktop application development, video & image editing, and company management.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 mt-md-0 mt-3'>
                                        <div className='team_card'>
                                            <img src='/assets/image/team2.png' alt='team' className='w-100' />
                                            <div className='team_deail'>
                                                <div>
                                                    <h3 className='team_name'>Gaurav Dubey</h3>
                                                    <p className='roadmap_content m-0'>TDeFi Representative</p>
                                                </div>
                                                <a href='https://www.linkedin.com/in/gauravdubeyith/' target='_blank'>
                                                    <img src='/assets/image/linkedin.svg' alt='linkedin' />
                                                </a>
                                            </div>
                                            <div>
                                                <p className='roadmap_content'>Chairman of TradeDog Group - an infrastructure to accelerate web3 startups and enterprises’ scalability.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Leads TDeFi, a web3 incubator and consulting firm, as the CEO.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>Holds 8+ patents & has been active in the brain-machine interface, intent prediction and behavior analysis.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 mt-lg-0 mt-3'>
                                        <div className='team_card'>
                                            <img src='/assets/image/team3.png' alt='team' className='w-100' />
                                            <div className='team_deail'>
                                                <div>
                                                    <h3 className='team_name'>Aaron Grinhaus</h3>
                                                    <p className='roadmap_content m-0'>Legal & Corporate Counsel</p>
                                                </div>
                                                <a href='https://www.linkedin.com/in/agrinhaus/' target='_blank'>
                                                    <img src='/assets/image/linkedin.svg' alt='linkedin' />
                                                </a>
                                            </div>
                                            <div>
                                                <p className='roadmap_content'>Internationally recognized tax lawyer, writer, web3 regulatory consultant  and founder of Grinhaus Law firm.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Published the world’s first textbook on Web3 law, now in its 3rd and international edition.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>Law degrees from the University of Ottawa (LL.B.), Michigan State University College of Law (J.D) and a Master in Tax Law from Osgoode Hall Law school of York University (LL.M.).</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}

                <section className='section_style documents_section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <span className='title_span'>Project</span>
                                <h2 className='title_h2 font_white mt-3 mb-5'>Roadmap</h2>
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-xl-3 col-lg-4 col-md-6 px-2 mb-3'>
                                        <div className='roadmap_card active'>
                                            <h3 className='roadmap_title'>April 2025</h3>
                                            <div>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Finalizing the Fone Network SDK</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Major Blockchain Improvements Update</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 px-2 mb-3'>
                                        <div className='roadmap_card'>
                                            <h3 className='roadmap_title'>Q2 2025</h3>
                                            <div>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Fone Network SDK Hackaton</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Crypto Cards Blockchain Update</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Fone Coin Launches on Exchanges</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 px-2 mb-3'>
                                        <div className='roadmap_card'>
                                            <h3 className='roadmap_title'>Q3 2025</h3>
                                            <div>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>FONE Phone Release Preparation</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>Green Karma Blockchain Update</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>More Fone Network games and apps to
                                                    be released by developers and though partnerships.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 px-2 mb-3'>
                                        <div className='roadmap_card'>
                                        <h3 className='roadmap_title'>Q4 2025</h3>
                                            <div>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content'>Fone Network system update to remove
                                                    legacy functionality.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>Unclaimed Fone Token Burn.</p>
                                                <div className='border_roadmap'></div>
                                                <p className='roadmap_content mb-0'>More Fone Coin Exchange
                                                    Listings</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='contact' className='section_style documents_section scroll_space pb-0'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='contact_card'>
                                    <div className='row contact_row'>
                                        <div className='col-lg-6'>
                                            <h2 className='title_h2 font_white my-0'>Contact Us</h2>
                                            <p className='contact_card_p'>Please fill out the form to get in <br
                                                className='d-lg-block d-none'/> touch with us.</p>
                                        </div>
                                        <div className='col-lg-6 mt-lg-0 mt-5'>
                                            <div className='d-md-flex align-items-center gap-3 mb-3'>
                                                <div className='position-relative w-100'>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Full Name"
                                                        aria-label="Fullname"
                                                        aria-describedby="basic-addon1"
                                                        value={formData.name}
                                                        onChange={e => {
                                                            setValidation({ ...validation, name: false })
                                                            setFormData({ ...formData, name: e.target.value })
                                                        }}
                                                    />
                                                    {validation.name && <div className='validations_text'>{'Please enter name'}</div>}
                                                </div>
                                                <div className='position-relative w-100'>
                                                    <input
                                                        type="email"
                                                        className="form-control mt-md-0 mt-3"
                                                        placeholder="Email Address"
                                                        aria-label="Emailaddress"
                                                        aria-describedby="basic-addon2"
                                                        value={formData.email}
                                                        onChange={e => {
                                                            setValidation({ ...validation, email: false })
                                                            setFormData({ ...formData, email: e.target.value })
                                                        }}
                                                    />
                                                    {validation.email && <div className='validations_text'>{formData.email ? 'Invalid email' : 'Please enter email'}</div>}
                                                </div>
                                            </div>
                                            <div className='position-relative w-100'>
                                                <textarea
                                                    className="form-control mb-3"
                                                    placeholder="Write an Message.."
                                                    aria-label="Write an Message.."
                                                    value={formData.message}
                                                    onChange={e => {
                                                        setValidation({ ...validation, message: false })
                                                        setFormData({ ...formData, message: e.target.value })
                                                    }}
                                                />
                                                {validation.message && <div className='validations_text textarea_validation'>{'Please enter your message'}</div>}
                                            </div>
                                            <button
                                                type='button'
                                                className='btn submit_btn'
                                                onClick={() => handleSubmit()}
                                                disabled={loading}
                                            >Submit</button>
                                        </div>
                                    </div>
                                    <div className='contact_card_video1'>
                                        <video autoPlay loop muted playsInline className='w-100'>
                                            <source src="/assets/image/video.mp4" />
                                        </video>
                                    </div>
                                    <div className='contact_card_video2'>
                                        <video autoPlay loop muted playsInline className='w-100'>
                                            <source src="/assets/image/video.mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    )
}

export default Home