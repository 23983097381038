import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function PrivacyPolicy() {
    return (
        <>

            <Header />

            <section className='section_style hero_section whitepaper_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='text-center mb-5'>
                                <img src="/assets/image/favicon.svg" alt="Fone Network" className='whitepapar_favicon' />
                                <h1 className='hero_title'>Privacy Policy</h1>
                                <p className='mb-0'>August 28th, 2023</p>
                            </div>
                            <div>
                                <h2>Legal Disclaimer: </h2>
                                <p>
                                    <strong>1. Risks related to the use of the Fone Network/Greenspace Application.</strong>
                                </p>
                                <p>
                                    Fone will not be responsible for any losses, damages or claims arising from events falling within the scope of the following categories:
                                </p>
                                <p>
                                    a. Mistakes made by the user of any cryptocurrency-related software or service, e.g., forgotten pins, payments sent to wrong coin addresses, accidental deletion of wallets, and/or forgetting to back up private keys.
                                </p>
                                <p>
                                    b. Software problems of the applications, wallets and/or any cryptocurrency-related software service, e.g., corrupted wallet file, incorrectly constructed transactions, unsafe cryptographic libraries, malware affecting the applications, wallet and/or any cryptocurrency-related software or service.
                                </p>
                                <p>
                                    c. Technical failures in the hardware of the user of any cryptocurrency-related software service, e.g., data loss due to a faulty or damaged storage device.
                                </p>
                                <p>
                                    d. Security problems experienced by the user of any cryptocurrency-related software service, e.g., unauthorized access to users' applications, wallets and/or accounts.
                                </p>
                                <p>
                                    e. Actions or inactions of third parties and/or events experienced by third parties, e.g., the bankruptcy of service providers, information security attacks on service providers, and fraud conducted by third parties.
                                </p>
                                <p>
                                    <strong>2. Compliance with tax obligations</strong> <br />
                                    The users of the applications are solely responsible to determinate what, if any, taxes apply to their crypto-currency transactions. The owners of, or contributors to, the wallet are NOT responsible for determining the taxes that apply to cryptocurrency transactions.
                                </p>
                                <p>
                                    <strong>3. No Warranties</strong> <br />
                                    The applications are provided on an "as is" basis without any warranties of any kind regarding the applications and/or any content, data, materials and/or services provided on the applications.
                                </p>
                                <p>
                                    <strong>4. Wallet Data Security</strong><br />
                                    The owners of, or contributors to, the wallet do not retain access at any time of the pin code, as well as the private keys entered or created by the cryptocurrency-related software. Instead, the information is encrypted and stored on the devices' storage with a well-known recommended method, Advanced Encryption Standard (AES). AES uses substitution–permutation network to encrypt user’s data with a key. Using this approach, it replaces bytes from one table with the bytes from another, and as such creates permutations of data.
                                </p>
                                <p>
                                    <strong>5. Limitation of liability</strong><br />
                                    Unless otherwise required by law, in no event shall the owners of, or contributors to, the applications be liable for any damages of any kind, including, but not limited to, loss of profits, or loss of data arising out of or in any way connected with the use of the applications. In no way are the owners of, or contributors to, the applications responsible for the actions, decisions, or other behavior taken or not taken by users in reliance upon the applications.
                                </p>
                                <p>
                                    <strong>6. Limitation of liability</strong><br />
                                    The user of the applications agrees to arbitrate any dispute arising from or in connection with the applications or this disclaimer, except for disputes related to copyrights, logos, trademarks, trade names, trade secrets or patents.
                                </p>
                            </div>
                            <div>
                                <h2>Security Policy:</h2>
                                <p>
                                    The intent of this Security policy is to protect the information assets of the organization and users, the main objective followed by is to establish and maintain adequate and effective security measures for the users to ensure that the confidentiality, integrity and operational availability of information is not compromised.
                                </p>
                                <p>Sensitive information must therefore be protected from unauthorized disclosure modification, access, use destruction or delay in service.</p>
                                <p>Each user has a duty and responsibility to comply with the information protection policies and procedures described in this document</p>
                                <p>
                                    <strong>1. Purpose</strong><br />
                                    The purpose of this policy is to safeguard information belonging to Fone within a secure environment.<br />
                                    This policy informs Fone user and staff authorized to use Fone applications of the principles governing the retention use and disposal of information.
                                </p>
                                <p>
                                    <strong>2. Scope</strong><br />
                                    This policy applies to all user who use Fone applications or work with documents or information that concerns customers or any other partner for whom the organization has collected information in the normal course of its business.
                                </p>
                                <p>
                                    <strong>3. Goals and Objectives Followed:</strong><br />
                                    The goals and objectives followed of this policy are:<br />
                                    <span>- Protect information from unauthorized access or misuse</span>
                                    <span>- Ensure the confidentiality of information</span>
                                    <span>- Maintain the integrity of information</span>
                                    <span>- Maintain the availability of information systems and information for service delivery</span>
                                    <span>- Comply with regulatory, contractual and legal requirements.</span>
                                    <span>- Maintain physical, logical, environmental and communications security</span>
                                    <span>- Dispose of information in an appropriate and secure manner when it is no longer in use</span>
                                    Which include deletion of all the information on a user’s account if requested by the user itself, providing information regarding his account. For the process to start the user needs to open a ticket through the application which the account is registered to.
                                </p>
                                <p>
                                    <strong>Steps:</strong><br />
                                    <span>a. Open the application.</span>
                                    <span>b. Open the menu from the side bar.</span>
                                    <span>c. Press Contact Support.</span>
                                    <span>d. Ask for deletion of the account</span>
                                    <span>e. Approve that you want your account to be permanently deleted.</span>
                                </p>
                                <p>
                                    <strong>4. Access Control:</strong><br />
                                    The fundamental element of this security policy is the control of access to critical information resources that require protection against unauthorized disclosure or modification. Access control refers to the permissions assigned to persons or systems that are authorized to access specific resources. Access controls exist at different layers of the system including the network. Access control is implemented by username and password. At the application and database level, other access control methods can be implemented to further restrict access. All access to the database is limited and only staff with specific privileges can access that information in order to assist the user. Also, historical data on the account to be reviewed by the staff for clearing out misunderstandings or occurred issues.
                                </p>
                                <p>
                                    <strong>5. Account Control:</strong><br />
                                    For each of our applications a user can own only one account associated with each app/game. If a user own multiple accounts he will be subject to a ban on all associated accounts. We reserve the rights to disable accounts when suspect suspicious activity.
                                </p>
                                <p>
                                    <strong>6. Security System</strong><br />
                                    As businesses, we need to be ever vigilant to ensure we are one step ahead of cyber threats and secure our data, technology, and networks from increasing threats. Our cyber security policy can provide a comprehensive and proactive response to threats of this nature that can be shared with our organization. As such if our system detects any violation the account detected will be permanently suspended. You will be able to open a ticket to our support team for an engineer to review the case <a href='mailto:support@fone.dev' target='_blank' className='font_blue'>(support@fone.dev)</a>. Upon reviewing the account and the final decision is that the account will be kept suspended all communication will be suspended, as such you may not get a reply on our follow up email. <br />
                                    Fone does not guarantee a bug-free environment, as such in cases where the user account is suspended by a bug related reason, the account will be reviewed by the companies’ engineers and it will be re-enabled, only when it is confirmed that it is a bug related issue.
                                </p>
                            </div>
                            <div>
                                <h2>Deposits Policy:</h2>
                                <p>
                                    <strong>1. Deposits:</strong><br />
                                    All deposits made to our applications have to deposit Fone (FONE-SOL). All deposits containing a different token other than FONE will not be processed and cannot be restored after the deposit process is done
                                </p>
                            </div>
                            <div>
                                <h2>Refund Policy:</h2>
                                <p>
                                    Thanks for purchasing our products from our applications operated by Fone
                                </p>
                                <p>
                                    We offer a full money back guarantee on all purchases made in our applications, but under certain conditions. We invite you to read the conditions explained in more detail below, in order to see the condition that applies to your situation, while following the correct procedure.
                                </p>
                                <p>
                                    After the three-day period, we cannot offer you a refund or exchange.
                                </p>
                                <p>
                                    To be eligible for a refund or exchange, all products must be unused. The purchase can also be eligible for a refund if it was uncredited to the account.
                                </p>
                                <p>
                                    If You have any further questions or would like to request a refund, please do not hesitate to contact us at <a href='mailto:support@fone.dev' target='_blank' className='font_blue'>support@fone.dev</a>
                                </p>
                                <p>
                                    <strong>Refunds (if applicable)</strong><br />
                                    <span>For the refund process to take place you need to provide us with the following information:</span>
                                    <span>a. The e-mail address linked to your account in the application</span>
                                    <span>b. The e-mail associated with the purchasing account (i.e., Google Play Account)</span>
                                    <span>c. The order number for the purchase (It will start with “GPA”)</span>
                                    <span>d. What you purchased and how much you paid</span>
                                    <span>e. Reason for refund</span>
                                    Please provide the information above only if it is applicable with the refund reason.
                                </p>
                                <p>
                                    <strong>Refunds on payments that did not go through</strong><br />
                                    If a purchase was made and it was either declined or cancelled, the payment will be invalid, and there will be no need for a refund, as the payment will not go through and will be back in your bank account, between three to five business days, depending on your bank and region.
                                </p>

                            </div>
                            <div>
                                <h2>GDPR Data Protection Policy:</h2>
                                <p>
                                    <strong>1. Introduction</strong><br />
                                    Fone is committed to conducting its business in accordance with all applicable Data Protection laws and regulations and in line with the highest standards of ethical conduct.
                                </p>
                                <p>
                                    This policy details expected behaviors of Fone’s Employees and Third Parties in relation to the collection, use, retention, transfer, disclosure and destruction of any Personal Data belonging to a Fone’s Customers and Staff (i.e., the Data Subject) and irrespective of the media used to store the information.
                                </p>
                                <p>
                                    Personal Data is any information (including opinions and intentions) which relates to an identified or Identifiable Natural Person. Personal Data is subject to certain legal safeguards and other regulations, which impose restrictions on how organizations may process Personal Data.
                                </p>
                                <p>
                                    An organization that handles personal data and makes decisions about its use is known as a Data Controller. Fone, as a Data Controller, is responsible for ensuring compliance with the Data Protection requirements outlined in this policy
                                </p>
                                <p>
                                    Non-compliance may expose Fone to complaints, regulatory action, fines and/or reputational damage.
                                </p>
                                <p>
                                    Fone’s leadership is fully committed to ensuring continued and effective implementation of this policy and expects all Fone Employees and Third Parties to share in this commitment.
                                </p>
                                <p>
                                    Any breach of this policy will be taken seriously and may result in disciplinary action or business sanction.
                                </p>
                                <p>
                                    <strong>2. Scope:</strong><br />
                                </p>
                                <p>
                                    <strong>2.1 This policy applies to all Fone Entities where a Data Subject’s personal data is processed:</strong><br />
                                    <span>- in the context of the business activities of the Fone Entity</span>
                                    <span>- for the provision or offer of goods or services to individuals (including those provided or offered free-of-charge) by Fone</span>
                                    <span>- to actively monitor the behavior of individuals.</span>
                                </p>
                                <p>
                                    <strong>2.2. Monitoring the behavior of individuals includes using data processing techniques such as persistent web browser cookies or dynamic IP address tracking to profile an individual with a view to:</strong><br />
                                    <span>- taking a decision about them</span>
                                    <span>- analyzing or predicting their personal preferences, behaviors and attitudes.</span>
                                </p>
                                <p>2.3. This policy applies to all processing of personal data in electronic form (including electronic mail and documents created with word processing software) or where it is held in manual files that are structured in a way that allows ready access to information about individuals.</p>
                                <p>
                                    2.4. This policy has been designed to establish a baseline standard for the processing and protection of personal data by all Fone Employees. Where national law imposes a requirement that is stricter than that imposed by this policy, the requirements in national law must be followed. Furthermore, where national law imposes a requirement that is not addressed in this policy, the relevant national law must be adhered to.
                                </p>
                                <p>
                                    2.5. The protection of personal data belonging to Fone Employees is not within the scope of this policy.
                                </p>
                                <p>
                                    2.6. The DPO is responsible for overseeing this Privacy Standard and, as applicable, developing Related Policies and Privacy Guidelines. The DPO within our company is Ivan Likov, email for correspondence ivanlikov@fone.dev.
                                </p>
                                <p>
                                    2.7. Please contact the DPO with any questions about the operation of this Privacy Standard or the GDPR, or if you have any concerns that this Privacy Standard is not being or has not been followed. You must always contact the DPO in the following circumstances:
                                    <span>- if you are unsure of the lawful basis which you are relying on to process Personal Data (including the legitimate interests used by the Company)</span>
                                    <span>- if you need to rely on Consent and/or need to capture Explicit Consent</span>
                                    <span>- if you need to draft Privacy Notices or Fair Processing Notices</span>
                                    <span>- if you are unsure about the retention period for the Personal Data being Processed</span>
                                    <span>- if you are unsure about what security or other measures you need to implement to protect Personal Data</span>
                                    <span>- if there has been a Personal Data Breach</span>
                                    <span>- if you are unsure on what basis to transfer Personal Data outside the EEA</span>
                                    <span>- if you need any assistance dealing with any rights invoked by a Data Subject</span>
                                    <span>whenever you are engaging in a significant new, or change in, Processing activity which is likely to require a DPIA or plan to use Personal Data for purposes others than what it was collected for if you plan to undertake any activities involving Automated Processing including profiling or Automated Decision-Making if you need help complying with applicable law when carrying out direct marketing activities; or</span>
                                    <span>- if you need help with any contracts or other areas in relation to sharing Personal Data with Third Parties (including our vendors).</span>
                                </p>
                                <p><strong>3. Policy</strong></p>
                                <p>
                                    <strong>3.1 Governance</strong>
                                    <span>3.1.1. Policy Dissemination and Enforcement</span>
                                    The management team of Fone must ensure that all Fone Employees responsible for the Processing of Personal Data are aware of and comply with the contents of this policy. In addition, Fone will make sure all Third Parties engaged to Process Personal Data on their behalf (i.e., their Data Processors) are aware of and comply with the contents of this policy. Assurance of such compliance must be obtained from all Third Parties, whether companies or individuals, prior to granting them access to Personal Data controlled by Fone.
                                </p>
                                <p>
                                    <span>3.1.2. Data Protection by Design</span>
                                    <span>To ensure that all Data Protection requirements are identified and addressed when designing new systems or processes and/or when reviewing or expanding existing systems or processes, each of them must go through an approval process before continuing.</span>
                                    <span>Fone must ensure that a Data Protection Impact Assessment (DPIA) is conducted, for all new and/or revised systems or processes for which it has responsibility. Fone should consult with a Data Protection subject matter expert during completing the DPIA. The subsequent findings of the DPIA must then be submitted to the senior risk office for Fone for review and approval. Where applicable, the Information Technology (IT) department, as part of its IT system and application design review process, will cooperate with the Data Protection subject matter expert to assess the impact of any new technology uses on the security of Personal Data.</span>
                                </p>
                                <p>
                                    <span>3.1.3. Compliance Monitoring</span>
                                    <span>To confirm that an adequate level of compliance is being achieved by Fone in relation to this policy, Fone will carry out an annual Data Protection compliance audit. Each audit will, as a minimum, assess compliance with this policy and the operational practices in relation to the protection of Personal Data, including:</span>
                                    <span>- the assignment of responsibilities</span>
                                    <span>- raising awareness</span>
                                    <span>- training of Employees</span>
                                    <span>- adequacy of organizational and technical controls to protect Personal Data</span>
                                    <span>- records management procedures (including data minimization)</span>
                                    <span>- adherence to the qualified rights of the Data Subject</span>
                                    <span>- Privacy by Design and Default</span>
                                    <span>- consent for direct marketing</span>
                                    <span>- Personal Data transfers</span>
                                    <span>- Personal Data incident management (including Personal Data breaches)</span>
                                    <span>- Personal Data complaints handling</span>
                                    <span>- the currency of Data Protection policies and Privacy Notices</span>
                                    <span>- the accuracy of Personal Data being stored</span>
                                    <span>- the conformity of Data Processor activities</span>
                                    <span>- the adequacy of procedures for redressing poor compliance.</span>
                                    <span>Any major deficiencies identified will be reported to and monitored by Fone Executive Management team.</span>
                                </p>
                                <p>
                                    <strong>3.2. Principles</strong>
                                </p>
                                <p>
                                    <span>3.2.1. Data Protection</span>
                                    <span>Fone has adopted the following principles to govern its collection, use, retention, transfer, disclosure and destruction of Personal Data.</span>
                                </p>
                                <p><strong>PRINCIPLE DEFINITION</strong></p>
                                <p>
                                    <strong>PRINCIPLE 1: </strong>
                                    Lawfulness, Fairness and Transparency Personal Data shall be processed lawfully, fairly and in a transparent manner in relation to the Data Subject. This means, Fone must tell the Data Subject what Processing will occur (transparency), the Processing must match the description given to the Data Subject (fairness), and it must be for one of the purposes specified in the applicable Data Protection regulation (lawfulness).
                                </p>
                                <p>
                                    <strong>PRINCIPLE 2:</strong> Purpose Limitation Personal Data shall be collected for specified, explicit and legitimate purposes and not further Processed in a manner that is incompatible with those purposes. This means Fone must specify exactly what the Personal Data collected will be used for and limit the Processing of that Personal Data to only what is necessary to meet the specified purpose.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 3:</strong> Data Minimization Personal Data shall be adequate, relevant and limited to what is necessary in relation to the purposes for which they are Processed. This means Fone must not store any Personal Data beyond what is strictly required.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 4:</strong> Accuracy Personal Data shall be accurate and kept up to date. This means Fone must have in place processes for identifying and addressing out-of-date, incorrect and redundant Personal Data.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 5:</strong> Storage Limitation Personal Data shall be kept in a form which permits identification of Data Subjects for no longer than is necessary for the purposes for which the Personal Data is Processed. This means Fone must, wherever possible, store Personal Data in a way that limits or prevents identification of the Data Subject.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 6:</strong> Integrity & Confidentiality Personal Data shall be Processed in a manner that ensures appropriate security of the Personal Data, including protection against unauthorized or unlawful Processing, and against accidental loss, destruction or damage. Fone must use appropriate technical and organizational measures to ensure the integrity and confidentiality of Personal Data are always maintained.
                                </p>
                                <p>
                                    <span>3.2.2. Accountability</span>
                                    The Data Controller shall be responsible for, and be able to demonstrate, compliance. This means Fone must demonstrate that the six Data Protection Principles (outlined above) are met for all Personal Data for which it is responsible.
                                </p>
                                <p><strong>3.3. Data Collection</strong></p>
                                <p>
                                    <span>Personal Data should be collected only from the Data Subject unless one of the following applies:</span>
                                    <span>- The nature of the business purpose necessitates collection of the Personal Data from other persons or bodies.</span>
                                    <span>- The collection must be carried out under emergency circumstances in order to protect the vital interests of the Data Subject or to prevent serious loss or injury to another person.</span>
                                    <span>- If Personal Data is collected from someone other than the Data Subject, the Data Subject must be informed of the collection unless one of the following apply:</span>
                                    <span>- the Data Subject has received the required information by other means</span>
                                    <span>- the information must remain confidential due to a professional secrecy obligation</span>
                                    <span>- a national law expressly provides for the collection, Processing or transfer of the Personal Data.</span>
                                    <span>Where it has been determined that notification to a Data Subject is required, notification should occur promptly, but in no case later than: 30 days for the Data Collector to provide the requested information from a user.
                                    </span>
                                </p>
                                <p>
                                    <span>3.3.1 Detailed explanation about Data Collection</span>
                                    <span>- This Privacy Policy describes how Fone collects and processes your personal information through the Fone applications that reference this Privacy Notice. Fone refers to an ecosystem comprising Fone mobile applications.</span>
                                    <span>- This Privacy Policy applies to all mobile applications and website. By using Fone Services, you are consenting to the collection, storage, processing, and transfer of your personal information as described in this Privacy Policy.</span>
                                    <span>- Phoneum Inc., (registered office address: 4786 Queen Street, Niagara Falls, Ontario L2E 2M3 Canada) is the Data Controller for personal information collected and processed in connection with provision of Fone Services.</span>
                                </p>
                                <p>
                                    <strong>a. What Personal Information About Users Does Fone Collect?</strong>
                                    <span>Fone collects your personal information to provide and continually improve our products and services.</span>
                                </p>
                                <p>
                                    <span>Here are the types of personal information we collect:</span>
                                </p>
                                <p>
                                    <span>Information you give us: we receive and store any information you provide in relation to Fone. You can choose not to provide certain information but then you might not be able to use some Fone Services. Examples of information that you give us include:</span>
                                    <span>- Email Address</span>
                                    <span>- First and Last name</span>
                                    <span>- Nationality</span>
                                    <span>- Other information to help us identify you</span>
                                    <span>Automatically Collected information: we automatically collect and store certain types of information about your use of Fone Services including your interaction with content and services available through Fone Services. Like many websites, we use cookies and other unique identifiers, and we obtain certain types of information when your web browser or device accesses Fone Services. Examples of the information we collect and analyze include:</span>
                                    <span>- Fone Services metrics (e.g., the occurrences of technical errors, your interactions with service features and content, and your settings preferences)</span>
                                    <span>- Version and time zone settings</span>
                                    <span>- Transaction history</span>
                                    <span>Information from other sources: we may receive information about you from other sources such as credit history information from credit bureaus, which we use to help prevent and detect fraud.</span>
                                </p>
                                <p>
                                    <strong>b. Can Children Use Fone Services?</strong><br />
                                    Fone does allow people under the age of 18 to use the Fone Services, as our applications strive to educate users on different age spectrum to learn more about crypto currencies.
                                </p>
                                <p>
                                    <strong>c. For What Purposes Does Fone Process My Personal Information?</strong><br />
                                    Fone does NOT process any user information. All the above-mentioned information is used to create unique accounts, without any duplication.
                                </p>
                                <p>
                                    <strong>d. What About Cookies and Other Identifiers?</strong><br />
                                    We do NOT use cookies and similar tools to collect information. Our website is only to inform the users of our activity and to have access to all our applications, exchange listings and social media.
                                </p>
                                <p>
                                    <strong>e. Does Fone Share My Personal Information?</strong><br />
                                    Information about our users is an important part of our business and we are not in the business of selling our users' personal information to others. Fone doesn’t not share any personal information for external use.
                                </p>
                                <p>
                                    <strong>f. How Secure is My Information?</strong><br />
                                    We design our systems with your security and privacy in mind. We work to protect the security of your personal information during transmission by using encryption protocols and software, provided by the Google services we use.
                                </p>
                                <p>
                                    <strong>g. How Long Does Fone Keep My Personal Information?</strong><br />
                                    We keep your personal information to enable your continued use of Fone Services, for as long as it is required to fulfil the relevant purposes described in this Privacy Notice, as may be required by law such as for tax and accounting purposes, or as otherwise communicated to you.
                                </p>
                                <p>
                                    <strong>h. Contact Information</strong><br />
                                    Our data protection officer can be contacted at <a href='mailto:contact@fone.dev' target='_blank' className='font_blue'>contact@fone.dev</a> / <a href='mailto:support@fone.dev' target='_blank' className='font_blue'>support@fone.dev</a>, and will work to address any questions or issues that you have with respect to the collection and processing of your personal information.
                                </p>
                                <p>
                                    <strong>i. Conditions of Use, Notices and Revisions</strong><br />
                                    <span>If you choose to use Fone Services, your use and any dispute over privacy is subject to this Notice and our Terms of Use. If you have any concerns about privacy at Fone, please contact us with a thorough description, and we will try to resolve it. You also have the right to contact your local Data Protection Authority.</span>
                                    <span>Our business changes constantly, and our Privacy Notice will change also. You should check our websites frequently to see recent changes. If you do not agree with the revised content, you shall stop accessing Fone immediately. When an updated version of the Privacy Policy is released, your continued access to Fone applications means that you agree to the updated content and agree to abide by the updated Privacy Policy. Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account.</span>
                                </p>
                                <p>
                                    <strong>j. Fone Network Location Sharing Permission</strong><br />
                                    <span>The app uses a plugin to detect Emulators, Rooted and Jailbroken devices, as well as VPNs. Due to the nature of the app, it does not allow Emulators and Rooted/Jailbroken devices, but requires to add the Location sharing permission, which is never used, but still listed under the permissions section. We never ask for users to share their location, nor the app checks in the background, as this feature is required when checking for VPNs, something that the app does not implement.</span>
                                </p>
                            </div>
                            <div>
                                <h2>User Terms and Conditions:</h2>
                                <p>
                                    Greenspace aims to be a safe platform that provide users with a positive experience. In order to achieve this, we ask out users to follow the subsequent guidelines.
                                </p>
                                <p>
                                    <strong>We ask our users to:</strong><br />
                                    <span>- Provide accurate information about yourself.</span>
                                    <span>- Create only one account (your own).</span>
                                    <span>- Not share your password or give access to your Greenspace account to others.</span>
                                </p>
                                <p>
                                    <strong>You cannot use Greenspace if:</strong><br />
                                    <span>- You are under 16 years old</span>
                                    <span>- We've previously disabled your account for violations of our Terms & Conditions</span>
                                </p>
                                <p><strong>Users agree not to engage in the conduct described below (or to facilitate or support others in doing so):</strong></p>
                                <p>
                                    1. You may not upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our Products.
                                </p>
                                <p>
                                    2. You may not access or collect data from our Products using automated means or attempt to access data you do not have permission to access.
                                </p>
                                <p>
                                    <strong>3. You may not use our Products to do or share the following:</strong><br />
                                    <span>a. Content that infringes or violates someone else's rights, including their intellectual property rights.</span>
                                    <span>b. Content including hate speech (abusive or threatening speech or writing that expresses prejudice against a particular group, especially on the basis of race, religion, or sexual orientation)</span>
                                    <span>c. Sexual content or nudity</span>
                                    <span>d. Photos or videos of another individual without their consent</span>
                                    <span>e. Content that reveals the personal or private information of others without their consent this includes but is not limited to:</span>
                                    <ol type="i" className='mb-0'>
                                        <li>Home address</li>
                                        <li>Date of birth</li>
                                        <li>Personal contact information</li>
                                        <li>Government identification</li>
                                        <li>Passwords</li>
                                        <li>Financial records</li>
                                    </ol>
                                    <span>f. Depicting, admitting to or promoting acts of physical harm against people or animals.</span>
                                    <span>g. Depicting, promoting, advocating for or encouraging participation in criminal activity this includes but is not limited to:</span>
                                    <ol type="i" className='mb-0'>
                                        <li>Theft</li>
                                        <li>Hacking</li>
                                        <li>Vandalization</li>
                                        <li>Bribery</li>
                                        <li>Embezzlement</li>
                                        <li> Money laundering</li>
                                    </ol>
                                    <span>h. Depicting, promoting, advocating for or encouraging participation in self harm or suicide</span>
                                    <ol type="i" className='mb-0'>
                                        <li>Attempts to buy, sell, gift or trade illegal goods. This includes but is not limited to: i. Firearms</li>
                                        <li>Drugs</li>
                                        <li>Illegal species/livestock</li>
                                        <li>Stolen information or goods</li>
                                    </ol>
                                </p>
                                <p>
                                    4. We are not liable for content associated with AI generation, The App only adds a "highres" tag to enhance the quality of the images generated. We do not alter the result in any other way.
                                </p>
                                <p>
                                    Greenspace is a community driven platform and encourages users to report content or conduct that you believe violates your rights or our term and policies. You can do so through the application or by contacting <a href='mailto:support@fone.dev' target='_blank' className='font_blue'>support@fone.dev</a>
                                </p>
                                <p>
                                    <strong>Steps:</strong><br />
                                    <span>1. Click on the post you would like to report</span>
                                    <span>2. Click the three dots in the upper right corner</span>
                                    <span>3. Select report and tell us why you believe the post should be removed</span>
                                </p>
                                <p>
                                    Greenspace reserves the right to remove any content that is in violation of these provisions and to remove or restrict access to your content and services if determined that doing so is reasonably necessary to protect the legal integrity of Greenspace. If your content is removed, you will not be refunded for any of the costs associated with minting the NFT or other related in app purchases conducted to upload the content in question.
                                </p>
                                <p>
                                    If we remove content that you have shared in violation of our Community Standards, we’ll let you know and explain why. If you believe that your content has been removed without good reason, you may appeal by contacting <a href='mailto:support@fone.dev' target='_blank' className='font_blue'>support@fone.dev</a>
                                </p>
                                <p>
                                    Greenspace does not guarantee a bug-free environment, as such in cases where the user account is suspended by a bug related reason, the account will be reviewed by the companies’ engineers and it will be re-enabled, only when it is confirmed that it is a bug related issue.
                                </p>
                            </div>
                            <div>
                                <h2>TERMS OF USE (EULA)</h2>
                                <p>
                                    By purchasing a Monthly Subscription, you agree to an initial <strong>and recurring Monthly Subscription fee</strong> at the then-current Monthly Subscription rate, and you accept responsibility for all recurring charges until you cancel your subscription. You may cancel your Monthly Subscription at any time, subject to the terms of our cancellation policy.
                                </p>
                            </div>
                            <div>
                                <h2>CANCELLATION POLICY</h2>
                                <p>
                                    You can cancel a monthly anytime after the first successful subscription. Please cancel using the respective account subscription section before your next billing day. If you do not wish to receive that month's subscription benefits. If you wish to email, please contact us at least three business days prior to your subscription's auto-renewal date at support@fone.dev. Auto-renewing subscriptions can be canceled mid-term.
                                </p>
                            </div>
                            <div>
                                <h2>DATA PRIVACY POLICY</h2>
                                <p>
                                    Greenspace is an entity of Fone.dev and follows the GDPR requirements for data protection summarized below.
                                </p>
                                <p>
                                    Greenspace is committed to conducting its business in accordance with all applicable Data Protection laws and regulations and in line with the highest standards of ethical conduct.
                                </p>
                                <p>
                                    This policy details expected behaviors of Greenspace’s Employees and Third Parties in relation to the collection, use, retention, transfer, disclosure and destruction of any Personal Data belonging to a Greenspace’s Customers and Staff (i.e., the Data Subject) and irrespective of the media used to store the information.
                                </p>
                                <p>
                                    Personal Data is any information (including opinions and intentions) which relates to an identified or Identifiable Natural Person. Personal Data is subject to certain legal safeguards and other regulations, which impose restrictions on how organizations may process Personal Data.
                                </p>
                                <p>
                                    An organization that handles personal data and makes decisions about its use is known as a Data Controller. Greenspace, as a Data Controller, is responsible for ensuring compliance with the Data Protection requirements outlined in this policy.
                                </p>
                                <p>
                                    Non-compliance may expose Greenspace to complaints, regulatory action, fines and/or reputational damage. Greenspace’s leadership is fully committed to ensuring continued and effective implementation of this policy and expects all Greenspace Employees and Third Parties to share in this commitment. Any breach of this policy will be taken seriously and may result in disciplinary action or business sanction.
                                </p>
                                <p>
                                    Greenspace has adopted the following principles to govern its collection, use, retention, transfer, disclosure, and destruction of Personal Data according to the following principles.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 1:</strong> Lawfulness, Fairness and Transparency Personal Data shall be processed lawfully, fairly and in a transparent manner in relation to the Data Subject. This means, Greenspace must tell the Data Subject what Processing will occur (transparency), the Processing must match the description given to the Data Subject (fairness), and it must be for one of the purposes specified in the applicable Data Protection regulation (lawfulness).
                                </p>
                                <p>
                                    <strong>PRINCIPLE 2:</strong> Purpose Limitation Personal Data shall be collected for specified, explicit and legitimate purposes and not further Processed in a manner that is incompatible with those purposes. This means Greenspace must specify exactly what the Personal Data collected will be used for and limit the Processing of that Personal Data to only what is necessary to meet the specified purpose.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 3:</strong> Data Minimization Personal Data shall be adequate, relevant, and limited to what is necessary in relation to the purposes for which they are Processed. This means Greenspace must not store any Personal Data beyond what is strictly required.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 4:</strong> Accuracy Personal Data shall be accurate and kept up to date. This means Greenspace must have in place processes for identifying and addressing out-of-date, incorrect, and redundant Personal Data.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 5:</strong> Storage Limitation Personal Data shall be kept in a form which permits identification of Data Subjects for no longer than is necessary for the purposes for which the Personal Data is Processed. This means Greenspace must, wherever possible, store Personal Data in a way that limits or prevents identification of the Data Subject.
                                </p>
                                <p>
                                    <strong>PRINCIPLE 6:</strong> Integrity & Confidentiality Personal Data shall be Processed in a manner that ensures appropriate security of the Personal Data, including protection against unauthorized or unlawful Processing, and against accidental loss, destruction, or damage. Greenspace must use appropriate technical and organizational measures to ensure the integrity and confidentiality of Personal Data are always maintained.
                                </p>
                            </div>
                            <div>
                                <h2>DATA COLLECTION</h2>
                                <p>
                                    Greenspace does not collect or store any of your data aside from your email and name used to create your account. Any further personal data will only be collected with your consent and for the following reasons.
                                </p>
                                <p>
                                    Personal Data should be collected only from the Data Subject unless one of the following applies:
                                </p>
                                <p>
                                    1. The nature of the business purpose necessitates collection of the Personal Data from other persons or bodies.
                                </p>
                                <p>
                                    2. The collection must be carried out under emergency circumstances in order to protect the vital interests of the Data Subject or to prevent serious loss or injury to another person.
                                </p>
                                <p>
                                    3. If Personal Data is collected from someone other than the Data Subject, the Data Subject must be informed of the collection unless one of the following apply:
                                </p>
                                <p>
                                    4. The Data Subject has received the required information by other means
                                </p>
                                <p>
                                    5. The information must remain confidential due to a professional secrecy obligation
                                </p>
                                <p>
                                    6. A national law expressly provides for the collection, processing, or transfer of the Personal Data.
                                </p>
                                <p>
                                    7. Where it has been determined that notification to a Data Subject is required, notification should occur promptly, but in no case later than: 30 days for the Data Collector to provide the requested information from a user.
                                </p>
                                <p>
                                    Greenspace does not sell any personal data collected to third parties.
                                </p>
                                <p>
                                    If you delete your content, it will no longer be visible to others, though it will still exist on our network. If you wish to have your data permanently erased, please submit a request to <a href='mailto:support@fone.dev' target='_blank' className='font_blue'>support@fone.dev</a>
                                </p>
                                <p>
                                    <strong>Steps:</strong><br />
                                    <span>a. Open the application.</span>
                                    <span>b. Open the settings from the bottom bar.</span>
                                    <span>c. Press Contact Support.</span>
                                    <span>d. Ask for deletion of the account</span>
                                    <span>e. Approve that you want your account to be permanently deleted.</span>
                                </p>
                            </div>
                            <div>
                                <h2>NFT GUIDELINES</h2>
                                <p>
                                    Before finalizing your NFT creation, have in mind that the following content will be removed if it is categorized by one of the topics:
                                </p>
                                <p>
                                    1. You may not upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our Products.
                                </p>
                                <p>
                                    2. You may not access or collect data from our Products using automated means or attempt to access data you do not have permission to access.
                                </p>
                                <p>
                                    3. You may not use our Products to do or share the following:
                                </p>
                                <p>
                                    <span>a) Content that infringes or violates someone else's rights, including their intellectual property rights.</span>
                                    <span>b) Content including hate speech (abusive or threatening speech or writing that expresses prejudice against a particular group, especially on the basis of race, religion, or sexual orientation)</span>
                                    <span>c) Sexual content or nudity</span>
                                    <span>d) Photos or videos of yourself, so called "selfies" or any other photos or videos of individuals and people</span>
                                    <span>e) Photos or videos of another individual</span>
                                    <span>f) Screenshots of any sort, including but not limited to mobile device setups, digital wallets or exchanges, stock and currency price charts, and anything else resebling a picture of what a device is showing on it's screen.</span>
                                    <span>g) Content that reveals the personal or private information of others without their consent this includes but is not limited to:</span>
                                    <ol type="i" className='mb-0'>
                                        <li>Home address</li>
                                        <li>Personal contact information</li>
                                        <li>Financial records</li>
                                    </ol>
                                    <span>h) Depicting, admitting to, or promoting acts of physical harm against people or animals.</span>
                                    <span>i) Depicting, promoting, advocating for, or encouraging participation in criminal activity this includes but is not limited to:</span>
                                    <ol type="i" className='mb-0'>
                                        <li>Theft</li>
                                        <li>Hacking</li>
                                        <li>Vandalization</li>
                                        <li>Bribery</li>
                                        <li>Embezzlement</li>
                                        <li>Money laundering</li>
                                    </ol>
                                    <span>j) Depicting, promoting, advocating for or encouraging participation in self harm or suicide</span>
                                    <span>k) Attempts to buy, sell, gift or trade illegal goods. This includes but is not limited to:</span>
                                    <ol type="i" className='mb-0'>
                                        <li>Firearms</li>
                                        <li>Drugs</li>
                                        <li>Illegal species/livestock</li>
                                        <li>Stolen information or goods</li>
                                    </ol>
                                </p>
                            </div>
                            <div>
                                <p>If your content is removed, you will not be refunded for any of the costs associated with minting the NFT or other related in app purchases conducted to upload the content in question.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}