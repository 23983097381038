import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Litepaper() {
    return (
        <>
            <Header />

            <section className='section_style hero_section whitepaper_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-lg-flex align-items-lg-center justify-content-between mb-5'>
                                <h1 className='hero_title'>Fone Network Litepaper</h1>
                                <a href='/assets/image/pdf/LightPaper.pdf' target='_blank' className='btn submit_btn mt-lg-0 mt-3'
                                >Download</a>
                            </div>
                            <div className='mb-3'>
                                <img src='/assets/image/litepaper/1.png' alt='fone network' />
                            </div>
                            <div>
                                <img src='/assets/image/litepaper/2.png' alt='fone network' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Litepaper